import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import jQuery from 'jquery'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import { io } from "socket.io-client"

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'
import InputSwitch from 'primevue/inputswitch'
import MultiSelect from 'primevue/multiselect'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Textarea from 'primevue/textarea'
import Sidebar from 'primevue/sidebar'
import InputNumber from 'primevue/inputnumber'
import Checkbox from 'primevue/checkbox'
import Image from 'primevue/image'
import Menu from 'primevue/menu'
import FileUpload from 'primevue/fileupload'
import FocusTrap from 'primevue/focustrap'
import Toolbar from 'primevue/toolbar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Paginator from 'primevue/paginator'
import Fieldset from 'primevue/fieldset'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

window.$ = window.jQuery = jQuery
window.axios = axios
window.moment = moment
window.lodash = lodash
window.socketIo = io(':3000')

const app = createApp(App)

app.component('Dialog', Dialog)
app.component('Button', Button)
app.component('InputText', InputText)
app.component('Password', Password)
app.component('Divider', Divider)
app.component('Calendar', Calendar)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Dropdown', Dropdown)
app.component('AutoComplete', AutoComplete)
app.component('InputSwitch', InputSwitch)
app.component('MultiSelect', MultiSelect)
app.component('Toast', Toast)
app.component('Badge', Badge)
app.component('Textarea', Textarea)
app.component('Sidebar', Sidebar)
app.component('InputNumber', InputNumber)
app.component('Checkbox', Checkbox)
app.component('Image', Image)
app.component('Menu', Menu)
app.component('FileUpload', FileUpload)
app.component('Toolbar', Toolbar)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Paginator', Paginator)
app.component('Fieldset', Fieldset)

app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
app.directive('focustrap', FocusTrap)

app.use(store)
app.use(router)
app.use(PrimeVue, {
    locale: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        dayNamesShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        dayNamesMin: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
        monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
        monthNamesShort: ["Янв", "Февр", "Март", "Апр", "Май", "Июнь","Июль", "Авг", "Сент", "Окт", "Нояб", "Дек"],
        today: 'Today',
        weekHeader: 'Wk',
        firstDayOfWeek: 1,
        dateFormat: 'dd.mm.yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found',
        emptyMessage: 'No available options'
    }
})
app.use(ToastService)

app.mount('#app')